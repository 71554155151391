@import '../../../variables'
.MuiPaper-root.header
  box-shadow: none
  .header-options
    color: $parso-blue-strong
    .username
      font-size: 15px
      padding: 5px 0 0 0
      margin: 0 0 3px 10px
  .header-toolbar
    z-index: 1
    min-height: 51px
    background: $parso-light-blue-light-gray
    .card-total, .header-logout
      display: none
  .logo-img
    height: 20px
