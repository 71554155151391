@import '../../../variables'

.FloorMap
  margin-bottom: 31px
  .leaflet-container
    height: calc(100vh - 239px)

  @media screen and (min-width: $md)
    .leaflet-container
      max-height: 600px
      height: calc(100vh - 340px)
