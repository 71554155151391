
@import '../../variables'

.SearchUser
  color: $parso-high-gray-blue
  height: 100vh
  min-height: 560px
  .card-total
    box-shadow: 0px 0px 3px rgba(162, 162, 162, 0.25)
    border-radius: 4px
    padding: 8px 20px
    color: $parso-high-gray-blue
    position: absolute
    right: 0
    bottom: 30px
    label
      font-size: 13px
      svg
        margin-right: 7px
    .value
      font-weight: 900
      font-size: 30px
    .percentage
      width: 40px
      font-size: 8px
      color: #8C8CA1

  h1
    font-weight: bold
    font-size: 60px
    line-height: 60px
  .subtitle
    font-size: 30px
    line-height: 43px
    color: $parso-high-gray-blue
  .map-wrapper
    width: calc(100vw - 441px)
    height: 100vh
    z-index: 1
    .top-info
      padding: 69px 36px 0
      h2
        font-size: 22px
        margin-bottom: 21px
      .legend
        margin-bottom: 5px
    .FloorMap
      margin-bottom: 0
      .leaflet-container
        height: calc(100vh - 178px)
  .welcome-bar
    z-index: 2
    width: 440px
    background: #F1F5FA
    height: 100vh
    padding: 0 35px
    box-sizing: border-box
    text-align: center
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25)
    h1
      font-size: 31px
      line-height: 31px
    p
      font-size: 20px
      line-height: 25px
    .welcome-logo-img
      position: fixed
      max-width: 230px
      max-height: 45px
      left: 50px
      top: 50px
    .welcome-buttons-wrapper button
      background: $parso-blue-strong

  .bigHeader
    .MuiPaper-root.header
      .header-toolbar
        background: white
        .header-logout
          display: block
          margin-top: 30px
          height: 75px
          color: $parso-high-gray-blue
  .layout
    padding: 0
  .item-carousel
    height: calc(100vh - 134px)
    &.form
      background: white
      height: calc(100vh - 76px)
      h1
        margin-bottom: 36px
      .form-wrapper
        width: 584px
