@import '../../variables'

.Success-reservation
  .success-box
    background: white
    margin: auto
    width: 316px
    height: 343px
    margin-top: 60px

    .success-icon.success-icon,
    .error-icon.error-icon
      color: $parso-success
      margin: auto
      display: block
      font-size: 32px

    .error-icon.error-icon
      color: #5F75B9

    .success-title
      color: #1C1939
      font-weight: 800
      font-size: 17px
      text-align: center
      line-height: 27px
      margin: 9px 0 7px

  .detail-list-wrapper
    margin-bottom: 40px

    .detail-list
      margin-bottom: 0
      li
        margin-bottom: 7px
      p
        margin-bottom: 15px
        &:last-of-type
          margin-bottom: 0

  header
    display: none

  @media screen and (min-width: $md)
    .success-box
      height: 425px
      margin-top: 30px
      .detail-list-wrapper
        background: #EFEFEF
        padding: 10px 13px
        border-radius: 4px

      .success-icon.success-icon,
      .error-icon.error-icon
        font-size: 40px

      .success-title
        font-size: 22px
        line-height: 34px
        margin: 22px 0 36px

      .detail-list
        li
          font-size: 14px
          margin-bottom: 8px

    header
      display: block
