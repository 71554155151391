@import '../../../variables'

.filters
  margin-bottom: 30px

  label
    display: flex
    align-items: center
    font-weight: 500
    font-size: 13px
    line-height: 18px
    color: $parso-high-gray

  .MuiInputAdornment-root
    color: #C4C4C4
    svg
      width: 15px

  .MuiInputBase-input
    font-style: italic
    font-weight: 400
    font-size: 13px
    line-height: 18px
    color: #525252
