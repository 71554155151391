@import '../../../variables'

.layout
  padding: 29px 36px 0
  display: flex
  .menu-wrapper
    button
      position: relative
      top: -13px
      left: -11px
  .content-wrapper
    width: 100%
    & > div.general-scroll
      height: calc($vh-100 - 103px)
      overflow-y: scroll
  @media screen and (min-width: $md)
    .content-wrapper > div.general-scroll
      height: calc($vh-100 - 120px)
