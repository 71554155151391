@import '../../../variables'

#VisitForm
  height: calc(100vh - 51px)
  overflow-y: scroll
  h1
    margin: 25px 0 0 0
    font-size: 34px
  p.subtitle
    margin: 0
    color: $parso-black-ligth-text
    font-size: 18px
    line-height: 25px
  .back-button
    position: relative
    left: -11px
    top: -11px
  .left-bar
    width: 406px
    position: fixed
    height: calc(100vh - 51px)
    box-shadow: 0px -20px 20px rgba(113, 133, 193, 0.25) inset
    background: $parso-light-blue-light-gray
    img
      max-width: 300px
      max-height: 60px

  .visit-form-select
    max-width: 376px
    &>div
      width: 100%
      padding: 4px 10px
      border-radius: 4px

  .form-bar
    overflow: scroll
    width: 750px
    padding: 40px 0 0 465px
    .autocomplete
      width: 376px
  .secction-title
    font-size: 18px
    width: 142px
    color: $parso-blue-strong
  .success-box
    text-align: center
    height: 364px
    width: 700px
    h1
      margin: 22px 0 27px 0
      font-size: 33px
    p
      font-size: 30px
    button
      margin-top: 108px
      width: 424px
    .success-icon.success-icon
      color: $parso-success-strong
      margin: auto
      display: block
      font-size: 40px
  .visit-form-grid
    text-align: left
    width: 744px
    margin-top: 39px
    .info-label
      text-align: right
      font-size: 12px
      width: 375px
      color: $parso-high-gray
    .MuiAutocomplete-inputRoot
      height: 33px
      .MuiAutocomplete-input
        padding: 0
        font-size: 16px
        width: auto
        height: 16px
    .cp-select.MuiOutlinedInput-root.MuiOutlinedInput-root
      width: 220px
      box-shadow: none
      &:hover .MuiOutlinedInput-notchedOutline
        border: solid 1px $parso-tertiary-gray
    .MuiGrid-item
      padding: 0px
    .visit-form-grid-form
      padding: 0 0 0 26px
    .MuiDivider-root
      border-color: $parso-light-blue-bar
    .MuiTypography-root
      font-size: 14px
    .MuiFormControlLabel-root svg
      font-size: 17px
    .finish-registration
      width: 264px
      margin: 20px 0 20px 112px
