@import '../../../variables'

.close-header.close-header
  margin-right: 0
  color: #50629C
  float: left

.title-header
  display: block
  text-align: center
  padding-top: 14px
  padding-right: 48px

@media screen and (min-width: $md)

  .close-header.close-header
    margin-right: 27px
    margin-top: 5px
    color: #50629C

  .title-header
    display: inline-block
    vertical-align: sub
    padding: 15px 0
