@import '../../../variables'

.am-pm.am-pm
  font-style: normal
  font-weight: 800
  font-size: 14px
  line-height: 19px
  color: $parso-super-light-gray
  &.active
    color: $parso-high-gray

.TimePicker
  width: 220px
  margin: auto
  label
    display: block
    font-style: normal
    font-weight: 800
    font-size: 12px
    line-height: 16px
    color: $parso-high-gray
    margin: 9px 5px 6px

.RangeTimePicker
  .MuiFormControlLabel-root
    align-items: baseline
    margin-left: 0
    margin-bottom: 10px

  .all-time
    position: relative
    left: -12px
    top: 5px

  .TimePicker
    margin-left: 10px

    &.disabled
      opacity: 0.5
      filter: grayscale(1)

  & .MuiTypography-root
    width: 70px
    font-size: 13px
    margin-bottom: 10px

.MuiButtonBase-root.item-timePicker
  font-style: normal
  font-size: 13px
  line-height: 0px
  align-items: center
  text-align: center
  color: #525252
  width: 21px
  height: 20px
  padding-top: 7px
  padding-left: 6px
  margin: 0px 11px 5px 0px

  &.active
    color: white
    background: $parso-blue
