@import '../../variables'

.New-reservation
  .item-icon.parking-schedules
    margin-left: 0
  .horizontal-steeper
    margin-bottom: 27px
    height: 36.5px
  .back-steeper
    color: $parso-blue
  .title-type
    text-align: center
    font-size: 33px
    font-weight: 500
    color: $parso-high-gray
    margin-bottom: 65px

  .MuiTypography-root
    font-size: 29px

  .radio-type
    padding: 0

  .MuiStepper-root.MuiStepper-root
    width: 200px

  .MuiOutlinedInput-root.MuiOutlinedInput-root
    background: #F1F5FA
    border: 0px

  .MuiInputLabel-root.MuiInputLabel-root,
  .MuiSelect-select.MuiSelect-select,
  .MuiInputBase-input.MuiInputBase-input
    font-size: 12px
    line-height: 16px
    padding: 11px 19px
    border-radius: 4px

  .MuiInputLabel-root.MuiInputLabel-root
    top: -20px
    left: -24px
    font-size: 14px
    font-weight: 500
    line-height: 19px
    display: block

  .new-reservation
    .div-reservation
      padding: 14px 23px 0
      color: $parso-high-gray
      h2
        display: none

      .form-wrapper
        height: calc(100vh - 211px)
        overflow-y: scroll
        padding-top: 30px
        padding-bottom: 20px
  .alert-form
    margin-top: 20px

  .hr-header.hr-header
    display: none

  .pre-label-form
    display: none

  .slide-0-wrapper
    width: 530px
    margin: 95px auto 0

    .MuiButton-root.MuiButton-root
      display: block
      margin: 75px auto 0 auto
    .MuiMenuItem-root
      min-width: 44px
      .MuiListItemIcon-root svg
        font-size: 24px

  @media screen and (min-width: $md)

    .MuiStepper-root.MuiStepper-root
      width: 215px

    .new-reservation
      padding: 47px 79px
      .div-reservation
        margin-top: 20px
        padding: 47px 57px
        box-shadow: 0px 0px 6px rgba(146, 146, 146, 0.5)
        color: $parso-high-gray
        h2
          display: block
          color: $parso-high-gray
          margin-bottom: 32px
          font-weight: 800

        .form-wrapper
          height: initial
          max-width: 363px

          &.workplace
            max-width: 663px

          & > div > .MuiGrid-item
            padding-top: 40px

    .hr-header.hr-header
      display: block
      border-color: $parso-light-blue

    .new-reservation-footer
      text-align: right
      margin-top: 0px

    .pre-label-form
      font-size: 18px
      line-height: 24px
      color: $parso-high-gray
      margin-bottom: 29px
      display: block

      &.disabled
        color: $parso-medium-gray
