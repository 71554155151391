@import '../../variables'

.Reservations
  .sub-header
    padding: 15px 0 44px 0
    h1
      font-size: 34px
      line-height: 47px
      margin-bottom: 14px
    p
      font-size: 30px
  .place-wrapper
    font-size: 20px
    color: $parso-high-gray-blue
    margin-bottom: 5px
    line-height: 36px
  .map-wrapper
    border: solid 1px #D2D2D2
    position: relative
    box-shadow: none
    width: 100%
    height: calc(100vh - 313px)
    min-width: calc(100vw - 500px)
    &.full
      height: calc(100vh - 267px)
    .default-map-text
      font-size: 20px
      color: $parso-high-gray-blue
      max-width: 363px
      padding: 0 20px
      height: 25px
  .reservation-wrapper
    height: calc(100vh - 274px)
    overflow-y: scroll
    min-width: 407px
    padding: 2px 6px 6px 6px
    .reservation
      border-radius: 6px
      cursor: pointer
      min-height: 235px
      width: 290px
      padding: 30px 55px 21px
      border: none
      box-shadow: 0px 0px 7.5477px rgba(146, 146, 146, 0.5)
      .MuiDivider-root
        width: 66px
        margin-top: 14px
        border-color: #D1DEEF
      label
        font-size: 16px
        color: $parso-black-ligth-text
        line-height: 28px
      h3
        font-size: 22px
      ul
        list-style-type: none
        font-size: 22px
        line-height: 27px
        color: $parso-high-gray
        margin: 18px 0 45px 0
        padding: 0
        li
          margin-bottom: 10px
          svg
            padding-right: 10px
      &:hover
        background: $parso-light-blue-light-gray
    .reservation.new
      color: $parso-blue-strong
      font-size: 30px
      min-height: 80px
      svg
        font-size: 30px
      label
        color: $parso-blue-strong
        font-size: 30px

@media screen and (min-width: $md)
  .MuiPaper-root

    .MuiDialogContent-root.dialog-detail-content
      box-sizing: border-box

      h3.dialog-title
        display: inline-block

      h4.dialog-subtitle
        display: inline-block

      .detail-footer
        left: 0
        right: 0
        margin: auto

        &.workplace
          max-width: initial

    .MuiDialogContent-root.dialog-info-content
      width: 600px
      box-sizing: border-box
      margin: auto
      padding: 100px 75px 30px

      .detail-header
        margin-bottom: 0
        margin-bottom: 0
        color: $parso-blue-strong
        font-size: 33px
        font-weight: 900
        margin-bottom: 41px
        svg
          margin-bottom: 22px

      .detail-hr.detail-hr
        margin: 10px 0 12px
        border-color: #B2BEE5
        display: block
        transform: scaleX(1.3)

      .detail-remove-info
        background: #F1F5FA
        padding: 10px 13px
        border-radius: 4px
        margin: 12px 0 32px

      .detail-list
        margin-bottom: 0
        li
          margin-bottom: 8px
          line-height: 22px

      p
        color: $parso-high-gray
        display: block
        font-size: 12px

      .detail-footer
        margin: auto
